<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          ref="crud_table"
          :fields="[
            //'id',
            'name',
            helpers.inputType,
            getHelperObject('imputation'),
            getHelperObject('apply_option', 'apply_to', null, 'details', true),
            helpers.currencyValue,
            helpers.buildings,
            helpers.monthNames,
            { ...helpers.validIcons, key: 'is_permanent' },
            { key: 'month_id', _classes: 'hide' },
            helpers.activeBadge
          ]"
          :config="{
            url: 'admin/property_scheduled_charges',
            route: '/admin/processes/scheduled_charges',
            name: 'scheduled charge',
            lname: 'property_scheduled_charges',
            params: { _lists: 'used_months,buildings,imputations,property_units' },
            filters: [{ key: 'month_id', multiple: true, default: 'response.data.month_ids', forced: true, list: 'used_months', callback: this.setMonths, buttonCallback: this.onGetData, config: { select_all: true } }, { key: 'imputation', config: { select_all: true } }, 'active'] // TODO: Add Building as parameter on Backend
          }"
          @preparedHelpers="onPreparedHelpers"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'ScheduledCharges',
  mixins: [crudMixin],
  data() {
    return {
      month_ids: ''
    }
  },
  methods: {
    setMonths(month_ids) {
      this.month_ids = month_ids
    },
    // TODO: create a fixed method ?
    onGetData() {
      // if (month_id) {
      // this.month = { month_id, _period_name: this.getPeriodName(month_id) }
      this.$refs.crud_table.getData(false, { month_ids: this.month_ids }, true)
      // }
    }
  }
}
</script>
